import {collection, getDocs, query, where} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {IEmployee, Department} from "./interfaces";

/**
 * Fetches employees from Firestore based on the specified department and filters.
 * @param department - The department to filter employees by ('All', 'Customer Support', 'Installation').
 * @returns A promise that resolves to an array of IEmployee objects.
 */
export async function fetchAvailableCrews(department: Department): Promise<IEmployee[]> {
  const employeesRef = collection(db, '_employees');

  // Start with a base query filtering active employees
  let q = query(employeesRef,
    where('employmentStatus', '==', 'Active'),
    where('departmentString', '==', department));

  if (department === 'Installation') {
    // Filter employees where departmentString == 'Installation' and seniorityString != 'Other (orange)'
    q = query(
      q,
      where('seniorityString', 'not-in', ['Other (orange)', 'Manager (green)'])
    );
  }

  try {
    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map the query results to IEmployee objects
    return querySnapshot.docs.map((doc) => {
      const employeeData = doc.data();
      return {
        id: doc.id,
        ref: doc.ref,
        ...employeeData,
      } as IEmployee;
    });
  } catch (error) {
    console.error('Error fetching employees:', error);
    return [];
  }
}
