import {IEvent, IFirestoreEmployee, IFirestoreEvent, IFirestoreProject, IFirestoreTraveler} from "./interfaces";
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  runTransaction,
  Timestamp,
  where
} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {User} from "firebase/auth";
import {getSearchIndexData} from "../utils/getSearchIndexData";
import {IEmailData, sendEmail} from "./sendEmail";
import {convertDateRangeToPrettyString} from "../utils/convertDateRangeToPrettyString";

// Add a flag to check if the environment is local
const isLocalEnvironment = process.env.NODE_ENV === 'development';
/**
 * Checks if a given date range overlaps with the next three weeks.
 *
 * @param {string} startDateStr - The start date string in ISO format.
 * @param {string} endDateStr - The end date string in ISO format.
 * @returns {boolean} True if any part of the date range is within the next three weeks, else false.
 */
function isDateRangeWithinNextThreeWeeks(startDateStr: string, endDateStr: string): boolean {
  const now = new Date();
  const threeWeeksFromNow = new Date();
  threeWeeksFromNow.setDate(now.getDate() + 21);

  const eventStartDate = new Date(startDateStr);
  const eventEndDate = new Date(endDateStr);

  // Check if the event ends before now but include events ending today
  if (eventEndDate < now && eventEndDate.toISOString().split('T')[0] !== now.toISOString().split('T')[0]) {
    // Event ends before now.');
    return false;
  }

  // Check if the event starts after three weeks from now
  if (eventStartDate > threeWeeksFromNow) {
    // Event starts after three weeks from now.
    return false;
  }

  // Include events starting today
  if (eventStartDate.toISOString().split('T')[0] === now.toISOString().split('T')[0]) {
    // Event starts today.
    return true;
  }

  // The event overlaps with the next three weeks
  return true;
}

interface IChangeDetails {
  type:
    | 'moved_into_next_three_weeks'
    | 'moved_out_of_next_three_weeks'
    | 'new_event_within_next_three_weeks'
    | 'updated_event_within_next_three_weeks';
  event: IFirestoreEvent;
  oldStartDate?: string;
  oldEndDate?: string;
}

/**
 * Saves an event to Firestore and triggers a webhook notification if conditions are met.
 *
 * @param {IEvent} eventDetails - The details of the event to be saved.
 * @param {User} user - The user who is saving the event.
 * @returns {Promise<void>} A promise that resolves when the event is saved.
 */
export async function saveEvent(eventDetails: IEvent, user: User | null): Promise<void> {
  /**
   * Convert the start and end dates to ISO strings if they are Date objects.
   */
  const startDate = eventDetails.start instanceof Date ? eventDetails.start.toISOString() : eventDetails.start;
  const endDate = eventDetails.end instanceof Date ? eventDetails.end.toISOString() : eventDetails.end;

  /**
   * Format the event details to match the Firestore schema.
   */
  const formattedEventDetails: IFirestoreEvent = {
    id: eventDetails.id,
    startDate,
    endDate: endDate ?? startDate,
    department: eventDetails.department,
    crew: eventDetails.crew ? [eventDetails.crew.ref] : [],
    notes: eventDetails.notes ?? null,
    customText: eventDetails.customText ?? null,
    eventType: eventDetails.eventType ?? null,
    traveler: eventDetails.traveler ? [eventDetails.traveler.ref] : [],
    lastModifiedBy: {
      id: user?.uid ?? 'unknown',
      time: Timestamp.now()
    },
    searchIndexData: getSearchIndexData(eventDetails)
  };

  try {
    const eventRef = doc(db, '_events', formattedEventDetails.id);

    await runTransaction(db, async (transaction) => {
      const eventSnapshot = await transaction.get(eventRef);

      let changeDetails: IChangeDetails | undefined;

      const isNowWithinNextThreeWeeks = isDateRangeWithinNextThreeWeeks(formattedEventDetails.startDate, formattedEventDetails.endDate);

      if (eventSnapshot.exists()) {
        // Existing event
        const existingEvent = eventSnapshot.data() as IFirestoreEvent;

        const wasWithinNextThreeWeeks = isDateRangeWithinNextThreeWeeks(existingEvent.startDate, existingEvent.endDate);

        // Condition 1: Existing event moved into the next 3 weeks
        if (!wasWithinNextThreeWeeks && isNowWithinNextThreeWeeks) {
          changeDetails = {
            type: 'moved_into_next_three_weeks',
            event: formattedEventDetails,
            oldStartDate: existingEvent.startDate,
            oldEndDate: existingEvent.endDate,
          };
        }

        // Condition 2: Existing event moved out of the next 3 weeks
        else if (wasWithinNextThreeWeeks && !isNowWithinNextThreeWeeks) {
          changeDetails = {
            type: 'moved_out_of_next_three_weeks',
            event: formattedEventDetails,
            oldStartDate: existingEvent.startDate,
            oldEndDate: existingEvent.endDate,
          };
        }
        // Condition 4: Existing event updated within the next 3 weeks
        else if (isNowWithinNextThreeWeeks) {
          changeDetails = {
            type: 'updated_event_within_next_three_weeks',
            event: formattedEventDetails,
            oldStartDate: existingEvent.startDate,
            oldEndDate: existingEvent.endDate,
          };
        } else {
          // do nothing as Event updated but not within next three weeks
        }
      } else {
        // New event
        if (isNowWithinNextThreeWeeks) {
          changeDetails = {
            type: 'new_event_within_next_three_weeks',
            event: formattedEventDetails,
          };
        } else {
          // do nothing as New event not within next three weeks
        }
      }

      if (changeDetails && user) {
        console.info('Sending notification...');
        await sendNotification(changeDetails, user);
      } else {
        // do nothing as changeDetails or user is missing.');
      }

      // Commit the new/updated event data
      transaction.set(eventRef, formattedEventDetails, {merge: true});
    });

    console.info('Event saved successfully.');

  } catch (error) {
    console.error('Error saving event to Firebase:', error);
  }
}

/**
 * Fetches the operations team members from Firestore.
 *
 * @returns {Promise<IFirestoreEmployee[]>} A promise that resolves to the ops team members.
 */
async function getOpsAdmins(): Promise<IFirestoreEmployee[]> {
  // Initial query without departmentString filtering
  const opsTeamQuery = query(
    collection(db, '_employees'),
    where('seniorityString', 'in', ['Executive (red)', 'Manager (green)']),
    where('employmentStatus', '==', 'Active')
  );

  const querySnapshot = await getDocs(opsTeamQuery);

  const allEmployees = querySnapshot.docs.map(doc => doc.data() as IFirestoreEmployee);


  const filteredEmployees = allEmployees.filter(employee => {
    const department = employee.departmentString || '';
    return department.includes('Production') || department.includes('Coatings & Assembly') || department.includes('Installation');
  });

  return filteredEmployees;
}

/**
 * Retrieves the project manager details for a given traveler.
 *
 * @param {DocumentReference} travelerRef - The Firestore document reference for the traveler.
 * @returns {Promise<{salesmanEmail: string | null, projectName: string, projectId: number}>} A promise that resolves to the project manager details.
 */
async function getProjectDetailsFromTraveler(
  travelerRef: DocumentReference
): Promise<{ salesmanEmail: string | null; projectName: string; projectId: number; calendarDisplayName: string }> {
  const travelerDoc = await getDoc(travelerRef);
  const travelerData = travelerDoc.data() as IFirestoreTraveler;

  const projectDoc = await getDoc(travelerData.project[0]);
  const projectData = projectDoc.data() as IFirestoreProject;

  const projectManagerDoc = await getDoc(projectData.projectManager[0]);
  const projectManagerData = projectManagerDoc.data() as IFirestoreEmployee;

  return {
    salesmanEmail: projectManagerData.primaryEmail ?? null,
    projectName: projectData.project,
    projectId: projectData.projectId,
    calendarDisplayName: travelerData.calendarDisplayName
  };
}

/**
 * Converts date range to a pretty string, showing old date with strikethrough if applicable.
 *
 * @param {string | null} oldStartDate - The old start date, if any.
 * @param {string | null} oldEndDate - The old end date, if any.
 * @param {string} newStartDate - The new start date.
 * @param {string} newEndDate - The new end date.
 * @returns {string} The formatted date range string.
 */
function formatDateRange(
  oldStartDate: string | null,
  oldEndDate: string | null,
  newStartDate: string,
  newEndDate: string
): string {
  const newDateRange = convertDateRangeToPrettyString(newStartDate, newEndDate);

  if (oldStartDate && oldEndDate) {
    const oldDateRange = convertDateRangeToPrettyString(oldStartDate, oldEndDate);
    if (oldDateRange !== newDateRange) {
      return `<s>${oldDateRange}</s> → ${newDateRange}`;
    }
  }
  return newDateRange;
}

/**
 * Sends a notification based on the change details and user information.
 *
 * @param {IChangeDetails} changeDetails - The details of the change that triggered the notification.
 * @param {User} user - The user who made the change.
 * @returns {Promise<void>} A promise that resolves when the notification is sent.
 */
async function sendNotification(changeDetails: IChangeDetails, user: User): Promise<void> {
  // 1. Only proceed if the department is 'Installation'
  if (changeDetails.event.department !== "Installation") {
    return;
  }

  /**
   * Retrieves the project details for the first traveler in the event.
   * If no traveler is present, sets the project details to null.
   *
   * @type {{salesmanEmail: string | null, projectName: string} | null}
   */
  const projectDetails: {
    salesmanEmail: string | null,
    projectName: string,
    projectId: number,
    calendarDisplayName: string
  } | null = changeDetails.event.traveler && changeDetails.event.traveler[0]
    ? await getProjectDetailsFromTraveler(changeDetails.event.traveler[0])
    : null;

  /**
   * Fetches the primary emails of all active operations team members.
   * Filters out any null or undefined emails.
   */
  const opsTeamEmails: string[] = await getOpsAdmins()
    .then((members) => members
      .map((member) => member.primaryEmail)
      .filter((email): email is string => email !== null && email !== undefined)
    );


  /**
   * Get the editor's email to exclude from recipients
   */
  const editorEmail = user.email || '';
  const additionalEmails = ['assistant-installation-manager@pwiworks.com'];


  /**
   * Build the recipients list by combining all emails and removing the editor's email.
   */
  const allEmails = projectDetails?.salesmanEmail
    ? [...opsTeamEmails, projectDetails.salesmanEmail]
    : opsTeamEmails;

  const recipients = allEmails.filter((email) => email !== editorEmail);

  additionalEmails.forEach(email => {
    if (email !== editorEmail) {
      recipients.push(email);
    }
  });

  const editorName = user.displayName || user.email || 'Someone';

  // Get event type
  const eventType = changeDetails.event.eventType || 'an event';

  // Get project name
  const projectName = projectDetails?.calendarDisplayName || changeDetails.event.customText || 'Unknown Project';

  // Prepare the email subject
  const emailSubject = `Schedule changed for ${projectName}`;
  // Prepare the email body
  let emailBody = '';

  // Switch based on change type to reflect correct action
  let actionPhraseA = '';
  let actionPhraseB = '';
  let emailSubjectPhrase = '';

  switch (changeDetails.type) {
    case 'moved_into_next_three_weeks':
      actionPhraseA = 'moved an';
      actionPhraseB = 'into';
      emailSubjectPhrase = 'Schedule changed';
      break;
    case 'moved_out_of_next_three_weeks':
      actionPhraseA = 'moved an';
      actionPhraseB = 'out of';
      emailSubjectPhrase = 'Schedule changed';
      break;
    case 'new_event_within_next_three_weeks':
      actionPhraseA = 'created a new';
      actionPhraseB = 'within';
      emailSubjectPhrase = 'Event added';
      break;
    case 'updated_event_within_next_three_weeks':
      actionPhraseA = 'updated an';
      actionPhraseB = 'within';
      emailSubjectPhrase = 'Event updated';
      break;
    default:
      actionPhraseA = 'updated the schedule';
      actionPhraseB = 'into';
      emailSubjectPhrase = 'Schedule updated';
  }

  emailBody += `<p>${editorName} has ${actionPhraseA} event for project ${projectName} ${actionPhraseB} the next three weeks.</p>`;
  emailBody += `<p>Event Type: ${eventType}</p>`;
  // Include date range
  const newStartDate = changeDetails.event.startDate;
  const newEndDate = changeDetails.event.endDate;

  const oldStartDate = changeDetails.oldStartDate || null;
  const oldEndDate = changeDetails.oldEndDate || null;

  const dateRangeStr = formatDateRange(oldStartDate, oldEndDate, newStartDate, newEndDate);

  emailBody += `<p>Date: ${dateRangeStr}</p>`;

  // Include event notes if any
  if (changeDetails.event.notes) {
    emailBody += `<p>Notes: ${changeDetails.event.notes}</p>`;
  }
  // Include event custom text if any
  if (changeDetails.event.customText) {
    emailBody += `<p>Custom Text: ${changeDetails.event.customText}</p>`;
  }

  // Construct departmentPath for the event URL
  const departmentPath = "installation";
  const eventUrl = `https://onsite-calendar.pwiworks.app/${departmentPath}/${changeDetails.event.startDate}`;
  const emailData: IEmailData = {
    to: [...recipients],
    from: {
      name: "PWI Onsite Calendar",
      email: "portal@pwiworks.com"
    },
    subject: `${emailSubjectPhrase} for ${projectName.substring(0, 20)}`,
    body: emailBody,
    replyTo: editorEmail,
    cta: {
      text: "View in Calendar",
      url: eventUrl
    }
  };

  // Skip sending email if running locally
  if (isLocalEnvironment) {
    console.log('Running in local environment. Skipping email sending.');
    return;
  }

  await sendEmail(emailData);
}
